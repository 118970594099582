<template>
	<div id="st-information">
		<el-row :gutter="20">
			<el-col :span="4">
		    	<SecondMenu type="1" />
			</el-col>
			<el-col :span="20">
				<el-row :gutter="20">
				    <el-col
				      v-for="(item, index) in rows"
				      :key="index"
				      :span="6"
				      style="padding-bottom: 10px;"
				    >
				      <el-card :body-style="{ padding: '0px' }">
				        <el-image
				          :src="item.avatar_url"
				          class="image"
				          fit="cover"
				        ></el-image>
				        <div style="padding: 14px" >
				          <!-- <span>{{ item.first_name }} {{ item.last_name }}</span> -->
				          <div class="bottom" style="margin-top: 0">
				            <span>{{ item.first_name }} {{ item.last_name }}</span>
				            <el-button type="text" class="button" @click="onAddProfile(index)">{{ $t('edit') }}</el-button>
				          </div>
				          <!-- <div class="bottom">
				            <time class="time">{{ handleDateShow(item.create_time) }}</time>
				            <el-button type="text" class="button" @click="onAddProfile(index)">{{ $t('edit') }}</el-button>
				          </div> -->
				        </div>
				      </el-card>
				    </el-col>
				    <el-col :span="6" style="padding-bottom: 10px;" v-if="add_btn && rows.length < finalProfileNum">
				    	<el-card shadow="hover" class="add-card" @click="onAddProfile(-1)"> 
					      	<div>
					      		<div class="icon"><i class="fa fa-plus"></i></div>
					      		<div class="text">{{ $t('add-subuser') }}</div>
					      	</div>
					    </el-card>
				    </el-col>
				</el-row>
			</el-col>
		</el-row>
		<el-drawer
		    :title="$t('router.st-information')"
		    v-model="drawer"
		    :direction="direction"
		    :before-close="handleClose"
		    size="42%"
		    style="overflow-y: auto;"
		    custom-class="tu-drawer">
		    <div>
		    	<el-form ref="formRef" :model="form" :rules="rules" label-width="120px">
		    		<el-form-item :label="$t('avatar')">
				      	<TuUpload show-type="avatar" :image-url="avatar_url.url" u-action="avatar" ref="avatar" @start="onUploadStart" @success="onUploadSuccess" />
				    </el-form-item>
				    <el-form-item label="First Name" prop="first_name">
				      	<el-input v-model="form.first_name" show-word-limit></el-input>
				    </el-form-item>
				    <el-form-item label="Last Name" prop="last_name">
				      	<el-input v-model="form.last_name" show-word-limit></el-input>
				    </el-form-item>
				    <el-form-item :label="$t('relationship')" v-if="isSubAccount">
				      	<el-radio-group v-model="form.relationship">
							<!-- <el-radio :label="0">{{ $t('unknown') }}</el-radio> -->
							<el-radio :label="1">{{ $t('Brother') }}</el-radio>
							<el-radio :label="2">{{ $t('Sister') }}</el-radio>
						</el-radio-group>
				    </el-form-item>
				    <!-- <el-form-item :label="$t('class-name')" prop="class_name">
				      	<el-input v-model="form.class_name" show-word-limit></el-input>
				    </el-form-item> -->
				    <!-- <el-form-item :label="$t('birthday')">
				        <el-select v-model="form.birthday" size="medium" clearable filterable :placeholder="$t('birthday')" style="width: 100%;">
							<el-option v-for="item, i in birthdayColumns" :label="item.name" :value="item.id"></el-option>
						</el-select>
				    </el-form-item> -->
				    <el-form-item :label="$t('what-subject')" prop="subject_id">
				    	<el-select v-model="form.subject_id" size="medium" clearable filterable placeholder="Select Subject" style="width: 100%;">
							<block v-for="item, index in subjectData.subject">
								<el-option v-for="it, i in item.children" :label="item.name + '(' + it.name + ')'" :value="it.id"></el-option>
							</block>
						</el-select>
				    </el-form-item>
				    <!-- <el-form-item :label="$t('usage frequency')">
				      	<el-radio-group v-model="form.use_rate">
							<el-radio :label="0">{{ $t('long-term use') }}</el-radio>
							<el-radio :label="1">{{ $t('use once') }}</el-radio>
						</el-radio-group>
				    </el-form-item> -->
				    <el-form-item :label="$t('school nature')">
				      	<el-radio-group v-model="form.school_nature">
							<el-radio :label="0">{{ $t('public') }}</el-radio>
							<el-radio :label="1">{{ $t('private') }}</el-radio>
							<el-radio :label="2">{{ $t('internationality') }}</el-radio>
						</el-radio-group>
				    </el-form-item>
				    <el-form-item :label="$t('solved-problem')">
				      	<el-input v-model="form.content" type="textarea" rows="3" show-word-limit></el-input>
				    </el-form-item>
				    <el-form-item>
				      <el-button type="primary" @click="onSubmit">{{ $t('Save') }}</el-button>
				      <el-button @click="drawer=false">{{ $t('cancel') }}</el-button>
				    </el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import TuUpload from '@/components/TuUpload'
import StudentApi from '@/api/student'
import TimeControl from '@/utils/time-control'

export default {
	name: "st-information",
	components: {
		SecondMenu,
		TuUpload
	},
	data() {
		return {
			drawer: false,
			direction: 'rtl',
			rows: [],
			add_btn: false,
			avatar_url: {},
			form: {
				profile_id: '',
				first_name: '',
				last_name: '',
				class_name: '-',
				subject_id: '',
				birthday: '2000',
				use_rate: 0,
				school_nature: 0,
				content: '',
				is_main: 0,
				relationship: 0 // 0 未知 1 兄弟 2 姐妹
			},
			birthdayColumns: [],
			rules: {
				first_name: [
					{
					    required: true,
					    message: this.$t('auth.enter-your-name'),
					    trigger: 'blur'
					}
				],
				last_name: [
					{
					    required: true,
					    message: this.$t('enter-your-last-name'),
					    trigger: 'blur'
					}
				],
				// class_name: [
				// 	{
				// 	    required: true,
				// 	    message: this.$t('Class information is required'),
				// 	    trigger: 'blur'
				// 	}
				// ],
				// subject_id: [
				// 	{
				// 	    required: true,
				// 	    message: this.$t('School courses must be selected'),
				// 	    trigger: 'blur'
				// 	}
				// ]
			},
			redirect: null
		}
	},
	computed: {
		...mapGetters([
			'userInfo','subjectData'
		]),
		finalProfileNum() {
			if(!!this.userInfo.profile_num) {
				return this.userInfo.profile_num + 1;
			}
			return 0;
		},
		isSubAccount() {
			// (!!rows && rows.length > 0 && form.is_main != 1) || profile_id == -1
			if((!!this.rows && this.rows.length > 0 && this.form.is_main != 1) || this.profile_id == -1) {
				return true;
			}
			return false;
		}
	},
	created() {
		this.redirect = this.$route.query.to
		this.getApiInfoData();
		// this.$store.dispatch('GetSubjectData')
		// 
		let birthday = [];
		let year = TimeControl.getFullYear();
		for(let i = year; i >= 1990; i--) {
		    birthday.push({label: i, id: i});
		}
		this.birthdayColumns = birthday;
		this.$store.dispatch('GetUserInfo')
	},
	methods: {
		onAddProfile(value) {
			let form = value == -1 ? {} : this.rows[value];
			this.form = {
				profile_id: form.profile_id || '',
				is_main: form.is_main || 0,
				first_name: form.first_name || '',
				last_name: form.last_name || '',
				class_name: form.class_name || '',
				subject_id: form.subject_id || '',
				birthday: form.birthday || '',
				use_rate: form.use_rate || 0,
				school_nature: form.school_nature || 0,
				content: form.content || '',
				relationship: parseInt(form.relationship || 0)
			}
			this.avatar_url = { code: '', url: form.avatar_url || '' }
			this.drawer = true;
		},
		getApiInfoData() {
			StudentApi.profile().then(res => {
				this.rows = res.data;
				this.add_btn = true;
			});
		},
		handleClose(done) {
			done();
		},
		handleDateShow(value) {
			return TimeControl.getFormatTime(value, 'L')
		},
		onUploadStart(value) {

		},
		onUploadSuccess(value) {
			this.avatar_url = {code: value.code, url: value.path};
		},
		onSubmit() {
			let that = this
			this.$refs.formRef.validate((valid) => {
				if(valid) {
					// if(!this.avatar_url || !this.avatar_url.url) {
					// 	this.$message.error("Avatar needs to be uploaded");
					// 	return false;
					// }
					if(this.isSubAccount && this.form.relationship == 0) {
						this.$message.error('You need to fill in the relationship with the main account')
						return false;
					}
					let form = this.form;
					form.profile_avatar_url = this.avatar_url.code;
					form.profile_first_name = form.first_name;
					form.profile_last_name = form.last_name;
					StudentApi.store(this.form).then(res => {
						this.$message.success(this.$t('Save Success'));
						this.getApiInfoData();
						this.drawer = false;
						this.$store.dispatch('GetUserInfo');
						if (!!this.redirect && this.redirect == 'order') {
							this.$router.push("/order")
						}
					});
				}
			})
		}
	}
}
</script>
<style lang="less">
#st-information {
	.time {
	  font-size: 13px;
	  color: #999;
	}

	.bottom {
	  margin-top: 13px;
	  line-height: 12px;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}

	.button {
	  padding: 0;
	  min-height: auto;
	}

	.image {
	  width: 100%;
	  height: 250px;
	  display: block;
	}

	.add-card {
		width: 100%;
		height: 99%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;

		-moz-user-select:none; /*火狐*/
		-webkit-user-select:none; /*webkit浏览器*/
		-ms-user-select:none; /*IE10*/
		user-select:none;

		.icon {
			text-align: center;
			font-size: 35px;
			padding: 5px 0;
		}

		.text {
			font-weight: 600;
		}
	}

	.tu-drawer {
		.el-drawer__body {
			padding: 0px 20px;
			overflow-y: auto;
		}
	}
}
</style>